<template>
  <b-form
    @submit.prevent="$emit('submit', model)"
    @keydown.enter="$emit('submit', model)"
  >
    <b-row>
      <b-col sm="12">
        <b-form-group label="Mes anterior" label-for="previous-month">
          <v-select
            id="previous-month"
            :value="model.previous_month"
            :options="[{ name: 'si', value: 1 }, { name: 'no', value: 0 }]"
            :reduce="item => item.value"
            label="name"
            @input="update('previous_month', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group label="Cliente" label-for="certification-client">
          <BaseSelect
            id="certification-client"
            :value="model.client"
            :resource="$data.$constants.RESOURCES.RESOURCE_CLIENTS"
            @input="update('client', $event)"
            :reduce="((item) => {return { id: item.id, name: item.name }})"
            :multiple="true"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group label="División" label-for="certification-division">
          <BaseSelect
            id="certification-division"
            :value="model.division"
            :resource="$data.$constants.RESOURCES.RESOURCE_DIVISIONS"
            http-method="get"
            @input="update('division', $event)"
            :reduce="((item) => {return { id: item.id, name: item.name }})"
            :multiple="true"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group label="Job" label-for="certification-job">
          <BaseSelect
            id="certification-job"
            :value="model.job"
            :resource="$data.$constants.RESOURCES.RESOURCE_JOBS"
            @input="update('job', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group label="Project Manager" label-for="certification-user">
          <BaseSelect
            id="certification-user"
            :value="model.user"
            :resource="$data.$constants.RESOURCES.RESOURCE_USERS"
            :http-body="{
              roles: ['project_manager', 'project_manager_contracting'],
            }"
            @input="update('user', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group label="Estado" label-for="certification-status">
          <StatusSelect
            id="certification-status"
            :value="model.status"
            :type="statusType"
            @input="update('status', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import FormMixin from "@/mixins/formMixin";
import { CERTIFICATIONS_STATUS_TYPE } from "@/api/status-api";
import StatusSelect from "@/components/status/select/StatusSelect.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";

export default {
  name: "CertificationReportsListFiltersForm",
  components: {
    BaseSelect,
    StatusSelect,
  },
  mixins: [FormMixin],
  data() {
    return {
      statusType: CERTIFICATIONS_STATUS_TYPE,
    };
  },
};
</script>

<style scoped></style>
